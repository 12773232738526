import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import configKeys from '../config/keys';
import logo from   "./images/logo-black.svg"
import "./css/progressSmall.css"
import "./css/progress.css"
import "./css/style.css"
import LoadingSpinner from "../components/LoadingSpinner";
import { downloadExcel } from "react-export-table-to-excel";

const TopBlocks = () => {
    const header = ["S.No.", "School Name"," DISE Code", "Block Name"];
    const location = useLocation().search
    const state_id = new URLSearchParams(location).get("state_id")
    const district_id = new URLSearchParams(location).get("district_id")
    const session = new URLSearchParams(location).get("session")
    const type = new URLSearchParams(location).get("type")
    const block_id = new URLSearchParams(location).get("block_id")
    const [currentBlock, setcurrentBlock] = useState([]);
    const [currentDistrict, setcurrentDistrict] = useState([]);
    const [topSchools, setTopSchools] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [excelFileName, setExcelFileName] = useState("");
    const [heading, setHeading]= useState("TEACHING AS PER ACADEMIC PLAN - % OF SCHOOLS")
    useEffect(() => {
    getSchools()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const  getSchools = async ()=>{
        try {
            setIsLoading(true);
            if (type === "nonperformers") {
                setHeading("TEACHING AS PER ACADEMIC PLAN - SCHOOLS NEED ATTENTION")
                const { data } = await axios.get(`${configKeys.SERVER_URL}/v2/nonPerformingSchools?state_id=${state_id}&district_id=${district_id}&block_id=${block_id}&session=${session}`);
                setTopSchools(data.data.topSchools);
                setcurrentDistrict(data.data.stateData.name.toLowerCase()+" - District "+data.data.districtData.name.toLowerCase())
                if (block_id) {
                    setExcelFileName("Schools Need Attention - District "+data.data.districtData.name+" - Block "+data.data.blockData.name)
                    setcurrentBlock(" - Block "+data.data.blockData.name.toLowerCase())
                } else {
                    setExcelFileName("Schools Need Attention - District "+data.data.districtData.name)
                    setcurrentBlock("")
                }
            } else {
                setHeading("TEACHING AS PER ACADEMIC PLAN - TOP SCHOOLS")
                const { data } = await axios.get(`${configKeys.SERVER_URL}/v2/topSchoolList?state_id=${state_id}&district_id=${district_id}&block_id=${block_id}&session=${session}`);
                setTopSchools(data.data.topSchools);
                setcurrentDistrict(data.data.stateData.name.toLowerCase()+" - District "+data.data.districtData.name.toLowerCase())
                if (block_id) {
                    setExcelFileName("Top Schools - District "+data.data.districtData.name+" - Block "+data.data.blockData.name)              
                    setcurrentBlock(" - Block "+data.data.blockData.name.toLowerCase())
                } else {
                    setExcelFileName("Top Schools - District "+data.data.districtData.name)              
                    setcurrentBlock("")
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    }

    function handleDownloadExcel() {
        let fileName = excelFileName
        downloadExcel({
            fileName: fileName,
            sheet: "react-export-table-to-excel",
            tablePayload: {
                header,
                // accept two different data structures
                body: topSchools,
            },
        });
    }

    function DateDisplay() {
        let makeDate = new Date();
        const currYear = makeDate.getFullYear();
        let startMonth = 'April';
        let currentSession = currYear+'-'+(currYear+1)

        if (currentSession === session) {
            let startYear = currentSession.split("-")[0]
            const currDay = makeDate.getDate();
            let currMonth = ''
            if (currDay >= 25) {
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            } else {
                makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            }
            if (currMonth === startMonth) {
                return <p className="duration">{'April '+startYear}</p>;
            } else {
                return <p className="duration">{'April '+startYear+' - '+currMonth+' '+currYear}</p>;
            }
        } else {
            return <p className="duration">{'April '+session.split("-")[0]+' - March '+session.split("-")[1]}</p>;
        }
    } 

    const topBlocksDashboard = (
        <div>
            {topSchools && topSchools.length > 0 && (
                topSchools.map((itemS,key) => 
                (
                    <>
                    <div className="row divider br">
                        <div className='col-md-1 left-bg- br d-flex align-items-center justify-content-center1'>
                            <div className="side-l-t">
                                <div className="side-l-t-content white1 ct1 p-2 pb-0 m-4 mb-0 mt-0">
                                    <p className="listing">{key+1}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 left-bg- br d-flex align-items-center justify-content-center1'>
                            <div className="side-l-t">
                                <div className="side-l-t-content white1 ct1 p-2 pb-0 m-4 mb-0 mt-0">
                                    <p className="listing">{itemS.school_name}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 left-bg- br d-flex align-items-center justify-content-center1'>
                            <div className="side-l-t">
                                <div className="side-l-t-content white1 ct1 p-2 pb-0 m-4 mb-0 mt-0">
                                    <p className="listing">{itemS.dise_code}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 left-bg- br d-flex align-items-center justify-content-center1'>
                            <div className="side-l-t">
                                <div className="side-l-t-content white1 ct1 p-2 pb-0 m-4 mb-0 mt-0">
                                    <p className="listing">{itemS.block_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                ))
            )}
        </div>
    )
  return (
    <div>
        {topSchools && topSchools.length > 0 && 
                (
                    <>
                    <div className="row selections">
                        <nav className="navbar navbar-expand-lg m-0 pb-2 pt-2 p-4" style={{justifyContent:'flex-end',backgroundColor:'#eeeeee'}}>
                        <div className="d-flex align-items-right">
                        <div className="collapse navbar-collapse" id="navmenu">
                        <ul className="navbar-nav ms-auto">
                            <li className="d-flex" >
                            <button className="btn btn-outline-success px-2" onClick={handleDownloadExcel}>DOWNLOAD EXCEL ⬇️</button>
                            </li>
                            {
                                type !== "nonperformers" && (
                                    <li  >
                                    <Link target="_blank" className="nav-block nav-link px-2 m-2 mt-0 mb-0" to={`/schoolList?state_id=${state_id}&district_id=${district_id}&block_id=${block_id}&session=${session}&type=nonperformers`} >Schools Need Attention</Link>
                                    </li>
                                )
                            }                            
                        </ul>
                        </div>
                        </div>
                        </nav>   
                    </div>
                    </>
                )}
        <div className="row">
            <div className="col-md-6 d-flex pt-4 pb-4" style={{justifyContent:'left'}}>
                <div className="bar-item m-4">
                    <img src={logo} alt="Logo" width={250} />
                </div>
            </div>
            <div className="col-md-6 d-flex pt-4 pb-4" style={{justifyContent:'right'}}>
                <div className="bar-item m-4">
                    <p className="red district">{currentDistrict} {currentBlock}</p>
                    {DateDisplay()}
                </div>
            </div>
        </div>
        {/* <!-- Block Section --> */}
        <div className="row">
            <div className="col-md bg-sec d-flex" style={{justifyContent:'space-evenly'}}>                
                <div className="bar-item d-flex" style={{justifyContent:'space-evenly',alignItems:'center'}}>
                    <h1 className="m-4 bold">
                    {heading}
                    </h1>
                </div>
            </div>
        </div>

        {/* <!-- Fifth Section --> */}
        <div className="row datasection">
            <div className="col-md-1 left-bg-4 d-flex align-items-center justify-content-center">
                <div className="side-l-t">
                    <div className="side-l-t-content white ct">
                        <p className="bold mt-4">S.No.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 left-bg-4 d-flex align-items-center justify-content-center">
                <div className="side-l-t">
                    <div className="side-l-t-content white ct">
                        <p className="bold mt-4">SCHOOL NAME</p>
                    </div>
                </div>
            </div>
            <div className="col-md-2 left-bg-4 d-flex align-items-center justify-content-center">
                <div className="side-l-t">
                    <div className="side-l-t-content white ct">
                        <p className="bold mt-4">DISE CODE</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 left-bg-4 d-flex align-items-center justify-content-center">
                <div className="side-l-t">
                    <div className="side-l-t-content white ct">
                        <p className="bold mt-4">BLOCK NAME</p>
                    </div>
                </div>
            </div>
        </div>
        {isLoading ? <LoadingSpinner /> : topBlocksDashboard}  
    </div>
  )
}

export default TopBlocks
